"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var contants_1 = require("./contants");
var controllers_1 = require("./controllers");
(function () {
    var raven;
    var context = {
        addToCartListeners: [],
    };
    function initAppForPage(_b, _a, _w, services) {
        var instance = _b.instance;
        raven = services && services.monitoring && services.monitoring.createMonitor(contants_1.SENTRY_DSN);
        var url = _.get(wix, 'location.baseUrl');
        raven && raven.setUserContext({
            id: url,
            url: url
        });
        context._instance = instance;
        return Promise.resolve();
    }
    var reportError = context.reportError = function (e) {
        raven && raven.captureException(e);
        throw e;
    };
    var wrapExportsRaven = utils_1.wrapExports(reportError);
    var controllerByType = {
        '1380bbc4-1485-9d44-4616-92e36b1ead6b': controllers_1.cartWidgetController,
        '13a94f09-2766-3c40-4a32-8edb5acdd8bc': controllers_1.productPageController
    };
    function createControllers(controllerConfigs) {
        return controllerConfigs
            .map(function (_b) {
            var type = _b.type;
            return controllerByType[type] ?
                Promise.resolve(wrapExportsRaven(controllerByType[type](context))) :
                Promise.resolve(controllers_1.emptyController(context));
        });
    }
    module.exports = wrapExportsRaven({
        initAppForPage: initAppForPage,
        createControllers: createControllers,
        exports: {
            setAddToCart: function (cb) {
                context.addToCart = cb;
            },
            setGetProduct: function () {
                throw new Error('Deprecated');
            },
            notifyOnAddToCart: function (item) {
                context.addToCartListeners.forEach(function (listener) { return listener(item); });
            }
        }
    });
})();
